import Ivrita from 'ivrita';
import { escapeRegExp, sortBy } from 'lodash-es';
import Vue from 'vue';
import Vuex from 'vuex';
// import createPersistedState from 'vuex-persistedstate';
import axios from 'axios';
import db from '@/services/db';
import { compQueryArgs } from '@/interfaces';
import router from '../router';
import colorOptions from './colorOptions.json';
// import Bagel from "@bageldb/bagel-db";
import manualBoards from './companyBoard';

const { users, collection, EQUAL } = db;
(window as any).db = db;
// import { token } from "./bageldb.json";

Vue.use(Vuex);
// const db = new Bagel(process.env.VUE_APP_BAGEL_TOKEN || token);
// const users = arg => db.users(arg);
// const collection = arg => db.collection(arg);
if (localStorage.getItem('vuex')) localStorage.removeItem('vuex');

const optimizeForRegX = (str) => {
  const newStr = Ivrita.genderize(str, Ivrita.MALE);
  // str.replace(/\/ת|s$/gi, '').replace(/ת /gi, ' ');
  return escapeRegExp(newStr).replace(/\s+/gi, '|');
};

const companiesCall = (companyName?: string) => {
  const call = collection('companies');
  if (companyName) {
    return call.query('name', 'regex', optimizeForRegX(companyName));
  }
  return call;
};
(async () => users('').logout())();

export default new Vuex.Store({
  state: {
    board: {} as Record<string, any>,
    search: '',
    loadingRemote: false,
    itemCount: 0,
    manualItemCount: 0,
    page: 1,
    manualPage: 1,
    searchCompanies: [] as any[],
    compPositions: [] as any[],
    companyCache: [] as any[],
    companies: [] as any[],
    manualCompanies: [] as any[],
    positions: [] as any[],
    manualPositions: [] as any[],
    allTags: [] as any[],
    tags: [] as any[],
    selectedTag: [] as any[],
    selectedTypes: [] as any[],
    selectedLocations: [] as any[],
    industries: [] as any[],
    selectedIndustry: [] as any[],
    locations: [] as any[],
    boardCompanies: [] as any[],
    remoteBoards: [] as any[],
    selectedRemote: [] as any[],
    remoteCompanies: [] as any[],
    selectedExperience: [] as any[],
    types: undefined as any,
    db,
    remoteFilterOpen: false,
  },
  // plugins: [createPersistedState()],
  mutations: {
    ADDITION(store, { state, data }) {
      store[state] = parseInt(store[state], 10) + parseInt(data, 10);
    },
    PUSH_ITEM(store, { state, data }) {
      store[state].push(data);
    },
    SET_ITEM(store, { state, data, index }) {
      store[state][index] = data;
    },
    SET_DATA(store, { state, data }) {
      store[state] = data;
    },
  },
  actions: {
    ...manualBoards.actions,
    toggleRemoteFilter({ commit, state: { remoteFilterOpen } }) {
      commit('SET_DATA', {
        state: 'remoteFilterOpen',
        data: !remoteFilterOpen,
      });
    },
    async getManualJobs(companyID, num, includeItemData = true) {
      let call = db
        .collection('jobs')
        .query('company.itemRefID', '=', companyID as unknown as string);
      call = num ? call.perPage(num) : call;
      call = includeItemData ? call : call.projectOn('_id');

      return call.get();
    },
    async newUserCompanyReq({ state /* commit */ }, companyForm) {
      // commit("setSignLoading", true);
      console.log({ companyForm });
      const selectedImage = companyForm.logo?.selectedImage;
      delete companyForm.logo;
      let result: any = await db.collection('companiesRequests').post({
        ...companyForm,
        applicantEmail: companyForm.applicationsEmail,
        status: { _id: 'c7r5dbi23aks739l2a6g' }, // ? User - Pending
        board: [{ itemRefID: state.board._id }],
        companyRef: companyForm?.companyRefID ? [{ itemRefID: companyForm.companyRefID }] : [],
      });
      const { status, data } = result;
      if (selectedImage) {
        const uploadResult = await db
          .collection('companiesRequests')
          .item(data.id)
          .uploadImage('logo', {
            selectedImage,
            imageLink: undefined,
            altText: selectedImage.name,
            fileName: selectedImage.name || 'logo',
          });
        result = { uploadResult, result };
      }
      if (status === 201) {
        console.log('POST success, here is the id of the new item:');
        console.log(data.id);
        return result;
      }
      // commit("setSignError", true);
      console.warn(data);
    },
    async getEmptyBranches() {
      const { data: companies }: any = await companiesCall()
        .projectOn('branchesNestedCollection,name')
        .perPage(900)
        .get();
      const emptyCompanies: any[] = [];
      const nonEmptyCompanies: any[] = [];
      for (const company of companies) {
        if (company.branchesNestedCollection.length == 0) {
          emptyCompanies.push(company);
        } else {
          nonEmptyCompanies.push(company);
        }
      }
      // console.log("emptyCompanies", emptyCompanies)
      // console.log("nonEmptyCompanies", nonEmptyCompanies)
    },
    async addAllBranches() {
      // ! not being used
      const { data: allBoards }: any = await collection('boards').get();
      for await (const board of allBoards) {
        const allCompanies: any[] = [];
        for await (const company of board.companies) {
          const { data: companyRef } = await companiesCall().item(company.itemRefID).get();
          const branches = companyRef.branchesNestedCollection.map((b) => ({
            itemRefID: b._id,
          }));
          const obj = {
            itemRefID: company.itemRefID,
            branchesNestedCollection: branches,
          };
          allCompanies.push(obj);
          // console.log(allCompanies.length)
        }
        // console.log(allCompanies)
        await collection('boards').item(board._id).put({ companies: allCompanies });
      }
    },
    async getSearchCompanies({ commit, state }) {
      commit('SET_DATA', { state: 'searchCompanies', data: [] });
      if (state.search?.length < 2) return;
      const escSearchStr = optimizeForRegX(state?.search);
      let companies;
      if (escSearchStr) {
        const regexp = new RegExp(escSearchStr, 'gi');
        companies = [...state.companies, ...state.manualCompanies].filter((company: any) => regexp.test(company.name));
      }
      commit('SET_DATA', { state: 'searchCompanies', data: companies });
    },
    setTypes({ commit, dispatch }, { selectedTypes, page }) {
      commit('SET_DATA', { state: 'selectedTypes', data: selectedTypes });
      if (page == '/') dispatch('getPositions');
    },
    setLocations({ commit, dispatch }, { locations, page }) {
      commit('SET_DATA', { state: 'selectedLocations', data: locations });
      if (page == '/') dispatch('getPositions');
    },
    setIndustry({ commit, dispatch }, industry) {
      commit('SET_DATA', { state: 'selectedIndustry', data: industry });
      dispatch('getPositions');
    },
    setExperience({ commit, dispatch }, experience) {
      commit('SET_DATA', { state: 'selectedExperience', data: experience });
      dispatch('getPositions');
    },
    setTags({ commit, dispatch }, tags) {
      commit('SET_DATA', { state: 'selectedTag', data: tags });
      dispatch('getPositions');
    },
    async nextPage({ state, dispatch }, options) {
      const page = +state.page;
      return dispatch('getPositions', { ...options, page: page + 1 });
    },
    logSearch(store, { searchTerm, results }) {
      if (searchTerm) {
        collection('searchLog').post({
          searchTerm,
          timestamp: new Date(),
          results,
        });
      }
    },
    // async filterBranches({ commit, getters }, jobs) { //! not being used
    //   const companies = getters.comps;
    //   let branches: any[] = [];
    //   companies.forEach((company) => {
    //     const b = company.branchesNestedCollection;
    //     branches = [...branches, ...b];
    //   });
    //   const branchesID = branches.map((b) => b.itemRefID);
    //   const newJobs = jobs.filter(
    //     (j) =>
    //       j.company[0].branchesNestedCollection?.[0] &&
    //       j.company[0].branchesNestedCollection.find((b) => {
    //         const includes = branchesID.includes(b.itemRefID);
    //         // console.log(includes)
    //         return includes;
    //       })
    //   );
    //   commit("SET_DATA", { state: "positions", data: newJobs });
    // },
    randomize(state, array) {
      for (let i = array.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        const temp = array[i];
        array[i] = array[j];
        array[j] = temp;
      }
      return array;
    },
    async addRemoteCompanies({ commit, state, dispatch }, remoteArray) {
      // console.log(remoteArray)
      commit('SET_DATA', { state: 'selectedRemote', data: remoteArray });
      commit('SET_DATA', { state: 'remoteCompanies', data: [] });
      for await (const remote of remoteArray) {
        const { data: remoteBoard }: any = await collection('boards')
          .query('domain', '=', remote)
          .everything()
          .get();

        const companies = remoteBoard[0].companies.map((company) => {
          const comp = company;
          comp.item.branchesNestedCollection = company.branchesNestedCollection;
          return comp.item;
        });
        for (const company of companies) {
          if (
            !state.companies.find((c: any) => c._id == company._id)
            && !state.remoteCompanies.find((c: any) => c._id == company._id)
          ) {
            commit('PUSH_ITEM', { state: 'remoteCompanies', data: company });
          }
        }
      }
      // commit("SET_DATA", {
      //   state: "remoteCompanies",
      //   data: [...state.remoteCompanies]
      // });
      // console.log(JSON.parse(JSON.stringify(companies)));
      dispatch('getPositions');
    },
    async prioritize({ state }, jobList) {
      let results: any[] = [];
      // for (const j of state.board.promotedJobs) {
      //   if (!state.selectedExperience) {
      //     const escSearchStr = optimizeForRegX(state.search);

      //     if (j.value.match(escSearchStr)) results.push(j.item);
      //   } else if (
      //     j.item.experienceLevel
      //     && state.selectedExperience.includes(j.item.experienceLevel._id)
      //   ) {
      //     results.push(j.item);
      //   }
      // }
      // ? comment next line to not randomize results
      // results = await dispatch("randomize", results);
      results = results.slice(0, 2);
      // for (let re of results)
      //   re.promoted = true;
      let jList = jobList;
      jList = jobList.filter((j) => !results.find((r: any) => r._id == j._id));
      // return await dispatch("randomize", [...results, ...jobList]);
      return [...results, ...jList];
    },
    async getPositions({
      commit, state, getters, dispatch,
    }, options) {
      let page = 1;
      if (options?.page) {
        page = +options.page;
      }
      const sortType = options?.sortType || '_lastUpdatedDate';
      const isAsc = options?.isAsc || 'desc';

      if (page == 1) commit('SET_DATA', { state: 'positions', data: [] });
      commit('SET_DATA', { state: 'page', data: page });

      const escSearchStr = optimizeForRegX(state.search);

      let branchesID: any[] = [];
      for (const company of getters.comps) {
        const b = company.branchesNestedCollection.map((br: any) => br._id || br.itemRefID);
        branchesID = [...branchesID, ...b];
      }
      const baseCall = () => collection('jobs')
        .pageNumber(page)
        .perPage(50)
        .query('active', EQUAL, 'true')
        .query('company.branchesNestedCollection.itemRefID', EQUAL, branchesID.join(','));

      let call = baseCall();

      if (state.selectedTag.length) {
        const tagIDs = state.selectedTag.map((tag: any) => tag._id);
        call = call.query('tags.itemRefID', EQUAL, tagIDs.join(','));
      }

      for (const f of getters.boardFilters) call.query(f.field, f.operation, f.value);

      if (state.search) call = call.query('relatedWords', 'regex', escSearchStr);
      if (state.selectedExperience.length) {
        call = call.query('experienceLevel._id', '=', state.selectedExperience.join(','));
      }

      try {
        const res: any = await call.sort(sortType, isAsc).get();
        if (state.search) {
          dispatch('logSearch', {
            searchTerm: state.search,
            results: res.data.length,
          });
        }

        commit('SET_DATA', {
          state: 'itemCount',
          data: +res.headers['item-count'],
        });

        const doSort = (pos) => {
          let result = pos;
          if (options?.sortType) {
            result = sortBy(result, sortType);

            if (isAsc === 'asc') return result.reverse();
          }

          return result;
        };
        // let positions = await dispatch("randomize", [
        //   ...res.data,
        // ]);
        let positions = await dispatch('prioritize', [...res.data]);

        if (page == 1) {
          commit('SET_DATA', {
            state: 'positions',
            data: doSort(positions),
          });
        } else {
          positions = doSort([...state.positions, ...positions]);
          commit('SET_DATA', {
            state: 'positions',
            data: doSort(positions),
          });
        }
        return positions;
      } catch (err: any) {
        throw new Error(err);
      }
    },
    search({ commit }, search) {
      commit('SET_DATA', { state: 'search', data: search });
    },
    setCount({ commit }, count) {
      commit('SET_DATA', { state: 'itemCount', data: count });
    },
    async nexCompaniesPage({ commit, state }) {
      commit('SET_DATA', { state: 'page', data: state.page + 1 });

      return new Promise((resolve, reject) => {
        const call = companiesCall()
          .query('owner.itemRefID', '=', '5e7a0c3afef22854c0ba4af1')
          .query('status._id', '=', 'bpf61ma23akg00e80p10')
          .pageNumber(state.page)
          .perPage(36);

        if (state.search) {
          const escSearchStr = optimizeForRegX(state.search);
          call.query('name', 'regex', escSearchStr);
        }
        call
          .get()
          .then((res: any) => {
            res.data.forEach((company) => commit('PUSH_ITEM', { state: 'companies', data: company }));
            resolve(state.companies);
          })
          .catch((err) => reject(err));
      });
    },
    async getBoardData({ commit }) {
      let b = window.location.href.replace(/^https?:\/\//, '').split('.')[0];
      if (process.env.NODE_ENV == 'development') b = process.env.VUE_APP_DEV_BOARD || 'jlm';
      const { data } = await axios.get(`/.netlify/functions/getBoard?b=${b}`);
      const {
        board, companies, industries, manualCompanies, tags, locations,
      } = data;

      const remoteBoards = board.boardExpansion || [];
      commit('SET_DATA', { state: 'remoteBoards', data: remoteBoards });
      commit('SET_DATA', { state: 'companies', data: companies });
      commit('SET_DATA', { state: 'manualCompanies', data: manualCompanies || [] });
      commit('SET_DATA', { state: 'tags', data: tags });
      commit('SET_DATA', { state: 'locations', data: locations });
      commit('SET_DATA', { state: 'board', data: board });
      commit('SET_DATA', { state: 'industries', data: industries });
    },
    // async getBoard({ commit, state }) {
    //   if (!state.allTags?.length) {
    //     const { data: allTags } = await collection('tags')
    //       .projectOff('_createdDate,_lastUpdatedDate')
    //       .get();
    //     commit('SET_DATA', { state: 'allTags', data: allTags });
    //   }
    //   return new Promise((resolve, reject) => {
    //     let board = window.location.href.replace(/^https?:\/\//, '').split('.')[0];

    //     collection('boards')
    //       .query('domain', '=', board)
    //       .everything()
    //       .get()
    //       .then((res: any) => {
    //         const [data] = res.data;
    //         resolve(state.board);
    //         const companies = data.companies.map((company) => {
    //           const comp = company;
    //           comp.item.branchesNestedCollection = company.branchesNestedCollection;
    //           return comp.item;
    //         });
    //         const manualCompanies = data.manualCompanies.map((c: any) => c.item);
    //         const remoteBoards = data.boardExpansion;
    //         const locations = data.locationFilters.map((location) => location.item);
    //         const tags = data.tagFilters.map((tag) => tag.item);
    //         commit('SET_DATA', { state: 'remoteBoards', data: remoteBoards });
    //         commit('SET_DATA', { state: 'companies', data: companies });
    //         commit('SET_DATA', {
    //           state: 'manualCompanies',
    //           data: manualCompanies,
    //         });
    //         commit('SET_DATA', { state: 'tags', data: tags });
    //         commit('SET_DATA', { state: 'locations', data: locations });
    //       })
    //       .catch((err) => reject(err));
    //   });
    // },
    getCompany({ commit, state }, id) {
      const findCompany = state.companies.find((company) => company._id == id);
      console.log('findCompany', findCompany);
      if (findCompany) return;
      companiesCall()
        .item(id)
        .get()
        .then((res: any) => commit('PUSH_ITEM', { state: 'companies', data: res.data }));
    },
    async findCompany({ state, commit }, id) {
      const findCompany = state.companyCache.find((company) => company._id == id);
      const mainCompany = state.companies.find((company) => company._id == id);
      if (mainCompany) {
        commit('PUSH_ITEM', { state: 'companyCache', data: mainCompany });
      } else if (!findCompany) {
        const res: any = await companiesCall().item(id).get();
        commit('PUSH_ITEM', { state: 'companyCache', data: res.data });
      }
    },
    async getSearchPositions({ state, dispatch }) {
      // const escSearchStr = optimizeForRegX(state.search);
      const comps = state.searchCompanies;
      console.log(comps);
      let results: any[] = [];

      for await (const comp of comps) {
        const data = await dispatch('getCompanyPositions', {
          companyID: comp._id,
          includeItemData: true,
        });
        console.log(data);
        // TODO: cleanup
        // if (!data?.length) {
        //   const { data: compData } = await dispatch("getManualJobs", comp._id);
        //   data = compData;
        // }
        // if (!data?.length) {
        //   const { data: compData }: any = await db
        //     .collection("jobs")
        //     .query("company.itemRefID", "=", comp._id)
        //     .get();
        //   data = compData;
        // }
        // TODO: cleanup
        results = [...results, ...data];
      }
      const hasActive = results?.filter((c) => c.manualJob || c.active)?.length;
      return hasActive ? results : [];
    },
    async getCompanyPositions(
      { getters }: any,
      { includeItemData, companyID, num }: compQueryArgs,
    ) {
      // const comp = getters.getCompany(companyID);
      const comp = getters.comps.filter((c) => companyID === c?._id);
      if (!comp.length) return { data: [] };
      const branches = comp[0].branchesNestedCollection.map((b) => b._id || b.itemRefID);
      console.log('branches', branches);
      if (branches.length) {
        let call = db
          .collection('jobs')
          .query('company.branchesNestedCollection.itemRefID', '=', branches.join(','))
          .query('company.itemRefID', '=', companyID as unknown as string)
          .query('active', EQUAL, 'true');

        call = includeItemData ? call : call.projectOn('_id');
        call = num ? call.perPage(num) : call;

        const res: any = await call.get();
        return res?.data || [];
      }
      let call = db
        .collection('jobs')
        .query('company.itemRefID', '=', companyID as unknown as string)
        .query('active', EQUAL, 'true');

      call = includeItemData ? call : call.projectOn('_id');
      call = num ? call.perPage(num) : call;

      // .query("company.itemRefID", "=", companyID)
      const res: any = await call.get();
      return res?.data || [];
    },
  },
  getters: {
    getCompany: (state) => (id) => state.companies.filter((c: any) => c._id === id),
    canChangeLang: (state) => state.board?.allowLanguageChanges,
    boardLanguage: (state) => {
      let lang;
      const yesWeCan = state.board?.allowLanguageChange;
      const boardLang = state.board.language;
      if (boardLang) {
        if (boardLang._id === 'c44hrpi23akg00eqq800') lang = 'he';
        if (boardLang._id === 'c44hrpi23akg00eqq80g') lang = 'en';
        if (!yesWeCan) return lang;
        lang = localStorage?.getItem('lang') || lang;
        lang = (router as any)?.history?.current?.query?.lang || lang;
      }
      return lang;
    },
    colorScheme: (state) => {
      let color = 'Default Blue';
      if (state.board.colorScheme) {
        color = state.board.colorScheme.value;
      }
      return colorOptions.find((c: any) => c.name == color);
    },
    boardFilters: (state) => state.board?.filters || [],
    board: (state) => state.board,
    itemCount: (state) => +state.itemCount + +state.manualItemCount,
    industryName: (state) => (id) => state.industries.find((industry) => industry._id == id),
    comps: (state) => {
      let companies: any[] = [...state.companies, ...state.manualCompanies];
      if (state.selectedLocations.length) {
        state.selectedLocations.forEach((location) => {
          companies = companies.filter(
            (company) => company.location[0] && company.location[0].itemRefID == location._id,
          );
        });
      }

      const remoteComps = state.remoteCompanies;

      // console.log(JSON.parse(JSON.stringify(remoteComps)));
      if (remoteComps.length) {
        for (const c of remoteComps) companies.push(c as any);
      }
      if (state.selectedTypes.length) {
        let filterSearch: any[] = [];
        state.selectedTypes.forEach((type) => {
          filterSearch = [
            ...filterSearch,
            ...companies.filter(
              (company: any) => !!(company.companyType && company.companyType._id == type._id),
            ),
          ];
        });
        companies = filterSearch;
      }
      if (state.selectedIndustry.length) {
        const filterSearch = companies.filter((company) => company.industries.find((i) => state.selectedIndustry.includes(i.itemRefID)));
        companies = filterSearch;
      }
      return companies.sort((a, b) => b.jobs - a.jobs);
    },
    // sortedPositions: (state) =>
    //   state.positions.sort(
    //     (a, b) => +new Date(b._lastUpdatedDate) - +new Date(a._lastUpdatedDate)
    //   ) || [],
    positions: (state) => state.positions || [],
    typeByID: (state) => (id) => state.types.find((type) => type._id == id),
    locationByID: (state) => (id) => state.locations.find((location) => location._id == id),
    cachedCompanyById: (state) => (id) => {
      let company;
      company = state.companies.find((c) => c._id == id);
      // if (!company)
      //   company = state.manualCompanies.find((c: any) => c._id == id);
      if (!company) {
        company = state.remoteCompanies.find((c: any) => c._id == id);
      }
      return company;
    },
  },
  modules: {},
});
