<template>
  <div class="job-list-item w-inline-block">
    <!-- v-if="company && company.logo" -->
    <router-link
      v-if="company._id"
      :to="'/company/' + company._id"
      class="job-list-logo"
    >
      <img
        :src="computedImage"
        alt="company logo"
      >
    </router-link>
    <!-- <div class="animation_wrapper">
      <div class="animation">
      </div> -->
    <div
      v-else
      class="job-list-logo"
    >
      <img
        :src="computedImage"
        alt="company logo"
      >
    </div>
    <!-- </div> -->
    <a
      target="_blank"
      class="jobs-list-txt-wrap"
      :href="linkToJob"
    >
      <p
        class="name-of-job-list"
        style="margin-bottom: 6px"
      >
        {{ position.position }}
      </p>
      <span
        v-if="position.promoted"
        style="color: #037afe"
      >Promoted</span>
      <span
        v-if="position.remote"
        class="pill"
      >{{ $t('remote') }} </span>
      <!-- <span class="location">{{locationMetro(position,company)}}</span> -->
      <div />
      <!-- {{ company }} -->
      <p
        v-if="company._id"
        :class="{ txt_underline: !position.companyName }"
        class="small-list-txt"
        @click.prevent="position.companyName ? {} : $router.push('/company/' + company._id)"
      >
        {{ company.name }}
      </p>
      <p class="small-list-txt gray">|</p>
      <div
        v-if="!position.companyName"
        class="small-list-txt gray"
      >
        <p
          v-for="branch in position.company[0].branchesNestedCollection"
          :key="branch._id"
        >
          {{ branch.value }}
        </p>
      </div>
      <!-- <p class="small-list-txt gray"><span class="bgl-font"></span>
      {{position.company.local}}Local</p>-->
      <p
        class="small-list-txt gray"
        style="direction: ltr"
      >
        <span>
          {{
            $timeAgo.format(
              Date.parse(position._lastUpdatedDate),
              locale === 'he' ? 'he' : undefined,
            )
          }}
        </span>
        <span class="bgl-font timeclock"></span>
      </p>
    </a>
    <div
      v-if="new Date(position._createdDate) > fiveDaysAgo"
      class="type-wrapper"
    >
      <p class="type-list">
        {{ $t('new') }}
      </p>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import fallbackImg from '@/assets/images/light-logo.svg';

export default {
  name: 'Position',
  props: {
    position: Object,
    locale: String,
    comp: Object,
  },
  methods: {
    companyType(id) {
      const type = this.typeByID(id);
      return type.value;
    },
    extractURL(text) {
      // eslint-disable-next-line no-useless-escape
      const urlRegex = /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/gi;
      const result = text.match(urlRegex);
      return result && result[0];
    },
  },
  computed: {
    computedImage() {
      const img = this.company?.logo
        ? `https://us-central1-devil-263810.cloudfunctions.net/images?imageID=${this.company?.logo?.imageName}`
        : fallbackImg;
      return img;
    },
    fiveDaysAgo() {
      const d = new Date();
      d.setDate(d.getDate() - 5);
      return d;
    },
    linkToJob() {
      return (
        this.position.jobLink
        || this.extractURL(this.position.jobDescription)
        || `/board/${this.company?._id}?position=${this.position?._id}`
      );
    },
    ...mapGetters(['cachedCompanyById', 'typeByID', 'comps']),
    company() {
      const company = this.comp?._id
        ? this.comp
        : this.comps.find((comp) => comp._id === this.position?.company?.[0].itemRefID)
          || this.position?.company;
      return company;
    },
  },
};
</script>

<style lang="scss" scoped>
.bgl-font {
  font-size: 15px;
  margin-right: 3px;
}
.pill {
  /* background-color: #0278ff32; */
  color: rgb(147, 147, 147);
}
.pill::before {
  content: ' ';
  display: inline-block;
  width: 8px;
  height: 8px;
  background: #ff7802;
  border-radius: 100%;
  margin-right: 5px;
  margin-bottom: 2px;
}

.job-list-logo {
  display: flex;
  place-items: center;
  img {
    width: 100%;
  }
}
</style>
