import { render, staticRenderFns } from "./Position.vue?vue&type=template&id=3624b1d8&scoped=true&"
import script from "./Position.vue?vue&type=script&lang=js&"
export * from "./Position.vue?vue&type=script&lang=js&"
import style0 from "./Position.vue?vue&type=style&index=0&id=3624b1d8&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3624b1d8",
  null
  
)

export default component.exports