var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{class:{ popupOpen: _vm.popupState, stopScroll: _vm.remoteFilterOpen },attrs:{"id":"app"}},[_c('lang-select',{on:{"changeLanguage":_vm.changeLanguage}}),_c('a',{staticClass:"powered",class:_vm.board.domain === 'br7' ? 'powered-footer' : '',attrs:{"href":"https://freshboard.city/il/","target":"_blank"}},[_vm._v("powered by "),_c('span',{staticClass:"blue"},[_vm._v("freshboard.city")])]),_c('div',{staticClass:"top-gray",style:({ background: _vm.colorScheme.gradient })},[_c('div',{staticClass:"_1200"},[_c('div',{staticClass:"top-logo-wrapper"},[_c('div',{staticClass:"logo top-btn-wraper white-logos"},[_c('div',{staticClass:"logos"},_vm._l((_vm.board.topLogoGallery),function(logo,i){return _c('div',{key:i,staticClass:"logo-img-top"},[_c('img',{attrs:{"src":logo.imageURL,"alt":logo.altText}})])}),0),_c('router-link',{attrs:{"to":"/"}},[_c('p',{staticClass:"paragraph"},[_vm._v(" "+_vm._s(_vm.board.name)+" ")]),_c('p',{staticClass:"subtitle",style:({
                color: _vm.colorScheme.name === 'white cream' && _vm.colorScheme.color,
              })},[_vm._v(" "+_vm._s(_vm.board.tagline)+" ")])]),_c('transition',{attrs:{"name":"enterExit"}},[(
                (_vm.board.domain == 'br7' && _vm.$route.name === 'Companies') ||
                  _vm.board.domain == 'test' ||
                  _vm.board.domain == 'jlm'
              )?_c('div',{staticClass:"new-top-btn white-btn w-button",on:{"click":function($event){_vm.popupState = true}}},[_vm._v(" "+_vm._s(_vm.$t('addMyCompany'))+" ")]):_vm._e()])],1)]),_c('div',{staticClass:"searchtop"},[_c('div',{staticClass:"tabs"},[_c('router-link',{staticClass:"top-links",attrs:{"to":"/","exact-path":""}},[_vm._v(" "+_vm._s(_vm.$t('jobs'))+" ")]),_c('router-link',{staticClass:"top-links",attrs:{"to":"/companies"}},[_vm._v(" "+_vm._s(_vm.$t('companies'))+" ")]),(_vm.tabLocation)?_c('div',{staticClass:"underline",style:({
              left: _vm.tabLocation.left + 'px',
              width: _vm.tabLocation.width + 'px',
            })}):_vm._e()],1),_c('div',{staticClass:"w-form searchBox"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.searchField),expression:"searchField"}],staticClass:"search-bar w-input",attrs:{"id":"Find-positions","type":"text","maxlength":"256","name":"Find-positions","placeholder":_vm.searchPlaceholder},domProps:{"value":(_vm.searchField)},on:{"focus":function($event){_vm.recommendHide = true},"input":function($event){if($event.target.composing)return;_vm.searchField=$event.target.value}}})]),(_vm.showRemoteFilters)?_c('div',{staticClass:"searchtop mb-5",class:{ closed: !_vm.remoteFilterOpen }},[_c('RemoteFilters')],1):_vm._e()])])]),_c('br'),_c('div',{staticClass:"_1200 counter paragraph"}),(_vm.companies.length >= 0)?_c('router-view',{attrs:{"board-language":_vm.boardLanguage},on:{"loading":_vm.setLoading}}):_vm._e(),_c('footer',[_c('div',{staticClass:"logos"},_vm._l((_vm.board.logoGallery),function(logo,i){return _c('img',{key:i,staticClass:"logo-img",attrs:{"src":logo.imageURL,"alt":logo.altText}})}),0),_c('popup',{attrs:{"name":"popupForm","title":_vm.$t('disclaimer')},model:{value:(_vm.popupState),callback:function ($$v) {_vm.popupState=$$v},expression:"popupState"}},[_c('AddMyCompForm',{attrs:{"name":"AddMyCompForm"}})],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }