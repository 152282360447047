
import { mapState, mapGetters } from 'vuex';
import { defineComponent } from '@vue/composition-api';
import langSelect from '@/components/langSelect.vue';
import AddMyCompForm from '@/components/AddMyCompForm.vue';
import RemoteFilters from '@/components/RemoteFilters.vue';
// import i18n from '@/plugins/i18n';
import '../assets/css/components.css';
import '../assets/css/jobsjlm.scss';
import '../assets/css/rtl.scss';
import 'vue-multiselect/dist/vue-multiselect.min.css';

export default defineComponent({
  name: 'NotApp',
  components: { langSelect, AddMyCompForm, RemoteFilters },
  props: {
    boardLanguage: String,
  },
  data() {
    return {
      tabLocation: null as any,
      timeout: null as any,
      recommendHide: false,
      loading: false,
      filter: false,
      searchField: '',
      searchPlaceholder: this.$t('search') as string,
      recommendations: [] as any[],
      // selectedLocations: [] as any[],
      companyFilters: [] as any[],
      selectedTypes: [] as any[],
      currentBoard: null as any,
      popupState: false,
    };
  },
  computed: {
    ...mapGetters(['colorScheme']),
    ...mapState([
      'remoteFilterOpen',
      'board',
      'search',
      'companies',
      'itemCount',
      'tags',
      'industries',
      'locations',
      'selectedTags',
      'selectedLocations',
      'types',
    ]),
    showRemoteFilters(): boolean {
      return this.$route.name !== 'Companies' && this.$route.name !== 'Company';
    },
  },
  watch: {
    boardLanguage() {
      this.updateTab();
    },
    $route() {
      this.updateTab();
    },
    selectedTypes() {
      this.$store.dispatch('setTypes', {
        selectedTypes: this.selectedTypes,
        page: this.$route.path,
      });
    },
    recommendations() {
      this.$store.dispatch('setTags', this.recommendations);
    },
    selectedLocations() {
      this.$store.dispatch('setLocations', {
        locations: this.selectedLocations,
        page: this.$route.path,
      });
    },
    searchField() {
      if (this.loading) return;

      clearTimeout(this.timeout);
      this.timeout = setTimeout(async () => {
        if (this.$route.name === 'Company') {
          this.$router.go(-1);
          return setTimeout(async () => {
            this.$store.dispatch('search', this.searchField);
            return this.makeRouteDecision();
          }, 400);
        }
        this.$store.dispatch('search', this.searchField);
        return this.makeRouteDecision();
      }, 600);
    },
  },
  async mounted() {
    this.searchField = this.search;
    window.addEventListener('resize', this.updateTab);
    // if (this.loading) return;

    // await this.makeRouteDecision();
    // this.$store.dispatch("getFilterOptions");
    // console.log(`board.topLogoGallery`, this.board.topLogoGallery);
  },
  methods: {
    getQuery() {
      return new Promise((resolve) => setTimeout(() => {
        const { query } = (this.$router as any).history.current;
        resolve(query);
      }, 150));
    },
    changeLanguage(event) {
      this.$emit('changeLanguage', event);
      this.updateTab();
    },
    updateTab() {
      this.$nextTick(() => {
        const tabs = document.querySelector('.tabs');
        const el = tabs?.querySelector('.is-active') || tabs?.querySelector('.router-link-active');
        const tabRect = tabs?.getBoundingClientRect();
        const rect = el?.getBoundingClientRect();

        this.tabLocation = {
          left: (rect?.left || -1000) - (tabRect?.left || 0),
          width: rect?.width,
        };
      });
    },
    setLoading(val) {
      this.loading = val;
    },
    async makeRouteDecision() {
      //  if (this.$route.path.match(/companies/)) {
      // }
      // if (this.loading) return;
      if (this.$route.path === '/') {
        if (this.searchField.length > 1 || this.searchField.length === 0) {
          await this.$store.dispatch('getSearchCompanies');
          await this.$store.dispatch('getPositions');
        }
      }
    },
    clearSearch() {
      this.searchField = '';
    },
  },
});
/* <style src="../assets/css/components.css"></style>
<style lang="scss" src="../assets/css/jobsjlm.scss"></style>
<style lang="scss" src="../assets/css/rtl.scss"></style>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style> */
