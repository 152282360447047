<script>
import { mapState } from 'vuex';
import i18n from '../plugins/i18n';

export default {
  name: 'RemoteFilters',
  data() {
    return {
      remoteOptions: false,
      localSelectedRemote: [],
    };
  },
  mounted() {
    this.localSelectedRemote = this.selectedRemote;
    if (this.localSelectedRemote.length) this.remoteOptions = true;
  },
  methods: {
    selectRemote() {
      this.$store.dispatch('addRemoteCompanies', this.localSelectedRemote);
    },
    toggleAllRemote() {
      if (this.remoteOptions) {
        this.localSelectedRemote = this.remoteBoards.map((r) => r.boardID);
      } else {
        this.localSelectedRemote = [];
      }
      this.selectRemote();
    },
  },
  computed: {
    locale: () => i18n.locale,
    ...mapState([
      'board',
      'search',
      'companies',
      'itemCount',
      'tags',
      'industries',
      'locations',
      'selectedTags',
      'selectedLocations',
      'selectedIndustry',
      'remoteBoards',
      'selectedRemote',
      'types',
    ]),
  },
};
</script>
<template>
  <div class="remoteWrap">
    <div
      v-if="remoteBoards.length"
      class="remoteWrap-inner"
    >
      <div
        v-if="board.domain !== 'br7'"
        class="fl-cat-head"
      >
        {{ $t('remotePositions').text }}
      </div>
      <div
        v-else
        class="fl-cat-head"
      >
        {{ $t('remotePositions').br7Text }}
      </div>
      <!-- <label class="w-checkbox switch">
          <div v-if="remoteBoards.length">
            <p class="fl-cat-head">{{ $t("remotePositions").text }}</p>
            <label class="w-checkbox switch">
              <input
                type="checkbox"
                v-model="remoteOptions"
                @change="toggleAllRemote"
                class="w-checkbox-input" />
              <span class="slider round w-form-label"> </span
            ></label> -->
    </div>
    <div
      v-if="board.domain !== 'br7'"
      class="remote-btns"
    >
      <label
        v-for="remote of remoteBoards"
        :key="remote.boardID"
        class="w-checkbox fl-check-wrap"
      >
        <input
          v-model="localSelectedRemote"
          type="checkbox"
          :value="remote.boardID"
          class="w-checkbox-input fl-check-box"
          @change="selectRemote"
        >
        <span class="fl-check-label w-form-label">
          {{ $t('remotePositions')[remote.title] }}
        </span>
      </label>
    </div>
    <div
      v-else
      class="remote-btns"
    >
      <label
        v-for="remote of remoteBoards"
        :key="remote.boardID"
        class="w-checkbox fl-check-wrap"
      >
        <input
          v-model="localSelectedRemote"
          type="checkbox"
          :value="remote.boardID"
          class="w-checkbox-input fl-check-box"
          @change="selectRemote"
        >
        <span class="fl-check-label w-form-label">
          {{ $t('remotePositions')[remote.title] }}
        </span>
      </label>
    </div>
  </div>
</template>

<style scoped>
.remoteWrap .remote-btns,
.remoteWrap-inner {
  display: inline-block;
}
.remoteWrap .fl-cat-head {
  margin-inline-end: 10px;
}
.remoteWrap .remote-btns label {
  display: inline-block;
  margin-inline-end: 20px;
}

@media screen and (max-width: 991px) {
  .remoteWrap {
    display: inline-block;
    position: relative;
    top: -10vh;
    margin: 0 40vw 0 -20vw;
    z-index: 100;
    left: 0vw;
  }
}
</style>
