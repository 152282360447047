// import Bagel from "@bageldb/bagel-db";
// import { token } from "./bageldb.json";

// const db = new Bagel(process.env.VUE_APP_BAGEL_TOKEN || token);

import db from '@/services/db';

const { collection } = db;

// const manualJobs = collection("manualJobs");

const jobs = () => collection('jobs');
const companies = () => collection('companies');

const actions = {
  getManualCompany: async (_store, companyID) => companies().item(companyID).get(),
  getManualJobs: async (_store, companyID) => jobs().query('company.itemRefID', '=', companyID).get(),
  getJobs: async (_store, companyID) => {
    try {
      const data = await db.collection('jobs').query('company.itemRefID', '=', companyID).get();

      // console.log(data);
      return data;
    } catch (error) {
      console.error(error);
      return undefined;
    }
  },

  createDefaultBranch: async (_store, companyID) => {
    try {
      const res = await companies().item(companyID).collection('branchesNestedCollection').post({
        branchLocation: [],
        branchName: 'HQ',
        rawLocation: 'HQ',
      });
      return res;
    } catch (error: any) {
      throw new Error(error);
    }
  },
  upsertManualPosition: async (_store, position) => {
    // return console.log(position);
    if (position._id) return await jobs().item(position._id).put(position);

    return await jobs().post(position);
  },
  updateCompanyInfo: async (_store, company) => collection('companies').item(company._id).put(company),
  deletePosition: async (_store, deletePosition) => {
    await jobs().item(deletePosition._id).delete();
  },
  deleteCompany: async (_store, company) => companies().item(company._id).delete(),
  applyNow: async (_store, { position, application: applicationData }) => {
    try {
      const { cv } = applicationData;
      // console.log(cv);
      delete applicationData.cv;

      applicationData = {
        ...applicationData,
        position: [{ itemRefID: position._id }],
      };

      const appCollection = collection('applications');

      const { data }: any = await appCollection.post(applicationData);
      if (cv) {
        await appCollection.item(data.id).uploadImage('cv', {
          selectedImage: cv.selectedImage,
          // imageLink: undefined,
          altText: 'cv',
          fileName: cv.name,
        });
      }
    } catch (error) {
      console.error(error);
    }
  },
};

export default {
  actions,
};
