
// @ is an alias to /src
import { mapState, mapGetters, mapActions } from 'vuex';
import { sortBy, uniqBy } from 'lodash-es';
import Vue from 'vue';
import jobFilter from '../components/jobFilter.vue';
import position from '../components/Position.vue';

export default Vue.extend({
  name: 'Home',
  components: {
    position,
    jobFilter,
  },
  props: {
    boardLanguage: String,
  },
  data() {
    return {
      openMenu: false,
      timeout: false,
      loading: true,
      loadedMore: false,
      noPosWithComps: false,
      fallbackPositions: [],
      selectedSortType: 'date',
      isAsc: null,
      sorted: false,
      sortTypes: [
        { value: 'date', asc: false },
        // { value: "name", asc: true },
      ],
      locale: 'en',
    };
  },
  computed: {
    ...mapState(['search', 'searchCompanies', 'selectedIndustry']),
    ...mapGetters(['positions', 'industryName', 'itemCount', 'comps']),
    processedSearchCompanies(): any[] {
      return uniqBy(this.searchCompanies, '_id');
    },
    computedItemCount(): number {
      return this.noPosWithComps ? this.fallbackPositions.length : this.itemCount;
    },
    computedPositions(): any[] {
      const {
        noPosWithComps, fallbackPositions, positions, selectedSortType, sorted,
      }: any = this;
      let currentJobs = noPosWithComps ? fallbackPositions : positions;
      if (selectedSortType && !sorted) {
        // this.sorted = true;
        // let sortedJobs: any;
        // sortedJobs = selectedSortType === 'date' ?
        //   currentJobs.sort((a, b) => Date.parse(b._lastUpdatedDate) - Date.parse(a._lastUpdatedDate)) :
        //   currentJobs.sort((a, b) => b.company.name - a.company.name);
        const sortedJobs = sortBy(currentJobs, '_lastUpdatedDate');
        // sortedJobs = selectedSortType === 'date' && (selectedSortType.asc) ? sorted : sorted.reverse();
        if (!this?.isAsc) sortedJobs.reverse();
        // this.sorted = true;
        currentJobs = sortedJobs;
      }
      // this.sorted = true;
      return uniqBy(currentJobs, '_id');
    },
  },
  watch: {
    positions: {
      async handler(val) {
        // if (this.loading) return;
        if (val?.length || (!val?.length && !this.searchCompanies?.length)) {
          this.fallbackPositions = [];
          this.noPosWithComps = false;
          return;
        }
        this.setLoading(true);
        this.fallbackPositions = await this.getSearchPositions();
        this.noPosWithComps = true;
        this.setLoading(false);
      },
      deep: true,
    },
    // comps() {
    //   return this.comps;
    // }
    //   search() {
    //     clearTimeout(this.timeout);
    //     this.timeout = setTimeout(() => {
    //       if (this.search.length > 1 || this.search.length === 0) {
    //         this.$store.dispatch("getPositions");
    //         this.$store.dispatch("getSearchCompanies");
    //       }
    //     }, 600);
    //   }
  },
  async mounted() {
    this.locale = this.$i18n.locale;
    this.setLoading(true);
    await this.getPositions();
    this.setLoading(false);
  },
  methods: {
    ...mapActions(['getSearchPositions', 'getPositions', 'nextPage']),
    setSelectedSortType({ value, asc }: Record<string, any>) {
      this.selectedSortType = value;
      this.isAsc = asc;
      this.openMenu = false;
      this.setLoading(true);
      this.getPositions({
        sortType: value === 'date' ? '_lastUpdatedDate' : 'company[0].value',
        isAsc: asc ? 'asc' : 'desc',
      });
      this.setLoading(false);
    },
    async scrollEvent() {
      const scrollRatio = (window.scrollY + window.innerHeight) / document.body.clientHeight;
      if (scrollRatio > 0.85 && !this.loading) {
        this.loading = true;
        await this.nextPage({
          sortType: this.selectedSortType === 'date' ? '_lastUpdatedDate' : 'company[0].value',
          isAsc: this.isAsc ? 'asc' : 'desc',
        });
        this.loading = false;
      }
    },
    async loadMore({ value, asc }: Record<string, any>) {
      this.loading = true;
      this.loadedMore = true;
      await this.nextPage({
        sortType: value === 'date' ? '_lastUpdatedDate' : 'company[0].value',
        isAsc: asc ? 'asc' : 'desc',
      });
      this.loading = false;
      window.addEventListener('scroll', this.scrollEvent);
    },
    setLoading(val) {
      this.sorted = val;
      this.loading = val;
      this.$emit('loading', val);
    },
  },
});
