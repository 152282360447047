import Vue from 'vue';
import VueRouter from 'vue-router';
import Home from '../views/Home.vue';
import Main from '../views/Main.vue';

Vue.use(VueRouter);
const routes = [
  {
    path: '/',
    name: 'Main',
    component: Main,
    children: [
      {
        path: '/',
        name: 'Home',
        component: Home,
      },
      {
        path: '/companies',
        name: 'Companies',
        component: () => import('../views/Companies.vue'),
      },
      {
        path: '/company/:id',
        name: 'Company',
        component: () => import('../views/Company.vue'),
      },
      {
        path: '/newPosition',
        name: 'newposition',
        component: () => import('../views/newPosition.vue'),
      },
    ],
  },
  {
    path: '/board/:companyID',
    name: 'Board',
    component: () => import('../views/companyBoard.vue'),
  },
];

const router = new VueRouter({
  mode: 'history',
  linkExactActiveClass: 'is-active',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    }
    return { x: 0, y: 0 };
  },
});

export default router;
