import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
// import he from "vuetify/lib/locale/he";
// import en from "vuetify/lib/locale/en";

Vue.use(Vuetify);

export default new Vuetify({
  // lang: {
  //   locales: { he, en },
  //   current: 'he',
  // },
});
