
import { mapState, mapActions } from 'vuex';
import { defineComponent } from '@vue/composition-api';
import stat from '../static.json';

const { experience } = stat;
// import i18n from '../plugins/i18n';
// import RemoteFilters from "./RemoteFilters.vue";

export default defineComponent({
  // components: { RemoteFilters },
  name: 'JobFilter',
  data() {
    return {
      remoteOptions: false,
      filterOpen: false,
      selectedIndustries: [],
      selectedTags: [],
      localSelectedRemote: [],
      selectedExperience: [],
    };
  },
  computed: {
    experienceLevel() {
      return experience;
    },
    locale() {
      return this.$i18n.locale;
    },
    sortedIndustries() {
      const industryList: any[] = this?.industries?.map(({ _id: id }) => ({
        id,
        title: this.computedIndustry(id, this.$i18n.locale),
      }));
      return industryList.sort((a, b) => (a.title > b.title ? 1 : -1));
    },
    ...mapState([
      'remoteFilterOpen',
      'board',
      'search',
      'companies',
      'itemCount',
      'tags',
      'allTags',
      'industries',
      'locations',
      'selectedTag',
      'selectedLocations',
      'selectedIndustry',
      'remoteBoards',
      'selectedRemote',
      'types',
    ]),
  },
  mounted() {
    this.selectedIndustries = this.selectedIndustry;
    this.selectedTags = this.selectedTag;
    this.localSelectedRemote = this.selectedRemote;
    if (this.localSelectedRemote.length) this.remoteOptions = true;
  },
  methods: {
    ...mapActions(['toggleRemoteFilter']),
    computedIndustry(id: string, lang?: string) {
      let name = '';
      const found: boolean = this.industries.find(({ _id, tech, heName }) => {
        const matched = id === _id;
        name = matched && lang === 'he' ? heName : tech;
        return matched;
      });
      return found ? name : '';
    },
    selectExperience() {
      this.$store.dispatch('setExperience', this.selectedExperience);
    },
    selectRemote() {
      this.$store.dispatch('addRemoteCompanies', this.localSelectedRemote);
    },
    toggleAllRemote() {
      if (this.remoteOptions) {
        this.localSelectedRemote = this.remoteBoards.map((r) => r.boardID);
      } else {
        this.localSelectedRemote = [];
      }
      this.selectRemote();
    },
    toggleFilter() {
      this.filterOpen = !this.filterOpen;
      this.toggleRemoteFilter();
    },
    setSelectedIndustry() {
      this.$store.dispatch('setIndustry', this.selectedIndustries);
    },
    setSelectedTag() {
      this.$store.dispatch('setTags', this.selectedTags);
    },
  },
});
