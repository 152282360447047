<template>
  <keep-alive>
    <div class="language-wrap">
      <select
        v-show="board.allowLanguageChange"
        v-model="i18n.locale"
        class="board-name language"
        @input="changeLanguage"
      >
        <option disabled>
          Language
        </option>
        <option value="he">
          עברית
        </option>
        <option value="en">
          English
        </option>
      </select>
      <popup
        v-model="popupState"
        :title="$t('disclaimer')"
      >
        <div class="newcom-pop disclaimer">
          <h3
            class="newcom-pop-head"
            :style="{
              background: colorScheme.gradient,
              color: colorScheme.name === 'white cream' && colorScheme.color,
            }"
          >
            {{ $t('disclaimer') }}
          </h3>
          <p class="disclaimerContent">
            {{ $t('disclaimerContent', { content: disclaimerContent }) }}
          </p>
        </div>
      </popup>
      <popup
        v-model="popupFormState"
        name="popupForm"
        :title="$t('disclaimer')"
      >
        <AddMyCompForm name="AddMyCompForm" />
      </popup>
      <div v-show="disclaimerContent"
        class="board-name language disclaimer-btn"
        @click="toggleDisclaimerPop"
      >
        <span>
          {{ $t('disclaimer') }}
        </span>
      </div>
      <div
        v-show="!hideAddCompanyAction"
        class="board-name language disclaimer-btn"
        @click="addMyCompany"
      >
        <span>
          {{ $t('addMyCompany') }}
        </span>
      </div>
    </div>
  </keep-alive>
</template>

<script>
import { mapGetters } from 'vuex';
import i18n from '@/plugins/i18n';
import AddMyCompForm from './AddMyCompForm.vue';

export default {
  name: 'LangSelect',
  components: { AddMyCompForm },
  props: {
    hideAddCompanyAction: Boolean,
  },
  data: () => ({
    popupState: false,
    popupFormState: false,
  }),
  computed: {
    ...mapGetters(['board', 'colorScheme']),
    disclaimerContent() {
      return i18n.locale === 'he' ? this.board.disclaimerContentHe : this.board.disclaimerContentEn;
    },
    i18n() {
      return i18n;
    },
  },
  methods: {
    async changeLanguage(event) {
      const val = event.target.value;

      this.$emit('changeLanguage', val);
    },
    toggleDisclaimerPop() {
      this.popupState = true;
    },
    addMyCompany() {
      this.popupFormState = true;
      // TODO: use popup with form
    },
  },
};
</script>

<style lang="scss" scoped>
.newcom-pop.disclaimer {
  margin-top: 200px !important;
}
.newcom-pop.disclaimer p {
  padding: 30px 50px !important;
  font-size: 18px;
  white-space: pre-line;
}
.language-wrap .comp-pop-box {
  text-align: start;
  max-width: none;
}
.pop-box .language-wrap .comp-pop-box {
  margin: 0 !important;
  max-width: 580px !important;
}
.newcom-pop-head {
  font-size: 20px !important;
  padding: 6px 0 12px 0 !important;
}
.disclaimerContent {
  padding: 20px !important;
}
.disclaimer {
  gap: 0rem !important;
}
select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.language {
  font-family: 'Almoni neue', Helvetica, sans-serif;
  height: 30px;
  border: none;
  background: transparent;
  border-radius: 100px;
  // padding: 0px 20px;
  text-align: center;
  color: white;
}

.language-wrap {
  position: absolute;
  top: 10px;
  z-index: 2;
  inset-inline-end: 0;
  display: flex;
  text-align: center;
  gap: 0;

  * {
    padding: 0;
    margin: 0 5px;
  }
}

@media screen and (max-width: 767px) {
  .language-wrap {
    top: 40px;
    width: 100%;
    place-content: center;
  }
}

.language:focus {
  outline: none;
}
/* .rtl .board-name.language{
 transform: translateX(-50px);
} */
.disclaimer-btn {

  display: inline-block;
}
.disclaimer-btn span {
  /* font-size: small; */
  position: relative;
  /* top: 3px; */
  /* left: 60px; */
  z-index: 5;
  display: inline-block;
  font-family: 'Almoni neue', Helvetica, sans-serif;
  height: 30px;
  border: none;
  background: transparent;
  border-radius: 100px;
  // padding: 0px 20px;
  text-align: center;
  color: white;
  cursor: pointer;
}
.language-wrap .popupForm {
  text-align: start;
}

@media screen and (max-width: 767px) {
  .disclaimer-btn:last-child span {
    display: none;
  }
}
</style>

<style>
.language-wrap .pop-box {
  max-width: 580px !important;
}
</style>
