var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"results"},[_c('div',{staticClass:"company-results _1200",class:_vm.processedSearchCompanies.length ? '' : 'cloaked'},[_c('h4',{staticClass:"comp-title"},[_vm._v(" Companies ")]),_vm._l((_vm.processedSearchCompanies),function(company){return _c('router-link',{key:company._id,staticClass:"w-inline-block company-box mini",attrs:{"to":'/company/' + company._id}},[_c('div',{staticClass:"company-logo small superSpecificCompanyLogo",style:('background-image: url(' + company.logo.imageURL + ')')}),_c('p',{staticClass:"company-name"},[_vm._v(" "+_vm._s(company.name)+" ")])])})],2),_c('div',{staticClass:"_1200"},[_c('div',{staticClass:"fl-wrap"},[_c('job-filter'),_c('div',{staticClass:"fl-right-side"},[_c('div',{staticClass:"someWrapper"},[_c('div',{staticClass:"fl-num-jobs"},[_vm._v(" "+_vm._s(_vm.computedItemCount.toLocaleString())+" "+_vm._s(_vm.$t('jobsFound'))+" ")]),_c('div',{staticClass:"sortControls"},[_c('button',{staticClass:"sort-btn",class:{ toggleAscBtn: true, isAsc: _vm.isAsc },on:{"click":function($event){_vm.selectedSortType &&
                  _vm.setSelectedSortType({
                    value: _vm.selectedSortType,
                    asc: !_vm.isAsc,
                  })}}},[_c('img',{attrs:{"src":require("../assets/images/sort-blue.svg")}})]),_c('div',{staticClass:"sort-opt-wrap"},[_c('button',{staticClass:"sort-opt",on:{"click":function($event){_vm.openMenu = !_vm.openMenu}}},[_vm._v(" "+_vm._s(_vm.selectedSortType ? _vm.$t('sortTypes')['types'][_vm.selectedSortType] : _vm.$t('sortTypes')['text'])+" "),_c('img',{attrs:{"src":require("../assets/images/c-up-blue.svg")}})]),_c('transition',{attrs:{"name":"enterExit"}},[(_vm.openMenu)?_c('div',{staticClass:"sort-opt-open"},_vm._l((_vm.sortTypes),function(type){return _c('div',{key:type.value,staticClass:"w-checkbox fl-check-wrap"},[_c('div',{staticClass:"s-opt",on:{"click":function($event){return _vm.setSelectedSortType(type)}}},[_vm._v(" "+_vm._s(_vm.$t(`sortTypes`)['types'][`${type.value}`])+" ")])])}),0):_vm._e()])],1)])]),_vm._l((_vm.selectedIndustry),function(industry){return _c('p',{key:industry,staticClass:"fl-tag"},[_vm._v(" "+_vm._s(_vm.$t(`industries`)[`${_vm.industryName(industry).tech}`])+" ")])}),(_vm.sorted || true)?_c('div',{staticClass:"fl-list-wrap"},[(!_vm.computedPositions.length && !_vm.fallbackPositions.length)?_c('div',{staticStyle:{"height":"70vh"}}):_vm._e(),_vm._l((_vm.computedPositions),function(position){return _c('position',{key:position._id,attrs:{"position":position,"locale":_vm.locale,"comp":undefined}})})],2):_vm._e()],2)],1),(!_vm.loading && _vm.computedItemCount > _vm.computedPositions.length)?_c('div',{staticClass:"load-animation w-embed",staticStyle:{"text-align":"center"}},[(!_vm.loadedMore)?_c('button',{staticClass:"small-btn w-button",staticStyle:{"margin":"auto"},on:{"click":function($event){return _vm.loadMore({
            value: _vm.selectedSortType,
            asc: _vm.isAsc,
          })}}},[_vm._v(" "+_vm._s(_vm.$t('loadMore'))+" ")]):_vm._e()]):(_vm.computedItemCount > _vm.positions.length)?_c('div',{staticClass:"load-animation w-embed full-loading",class:_vm.loading ? '' : 'opacity'},[_c('loading')],1):_vm._e()])])
}
var staticRenderFns = []

export { render, staticRenderFns }