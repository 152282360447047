import Vue from 'vue';
import VueI18n from 'vue-i18n';

Vue.use(VueI18n);

const texts = {
  en: {
    tags: 'Tags',
    sortTypes: {
      text: 'sort',
      types: {
        date: 'date',
        name: 'Company Name',
      },
    },
    imageError: 'Image size should be less than 10 MB!',
    linkError: 'You must provide a valid link',
    compNameError: 'There is already a company with that name',
    success: 'Request sent - add another company',
    fail: "Something isn't right",
    applyHere: 'Apply Here',
    addMyCompany: 'Add My Company',
    disclaimer: 'disclaimer',
    disclaimerContent: '{content}',
    'Company Name*': 'Company Name*',
    Tagline: 'Tagline',
    'Email for Applications*': 'Email for Applications *',
    'Company career page link': 'Company career page link',
    Notes: 'Notes',
    description: {
      value: 'Description',
      title:
        'What does the company do in a few words? (This can also be copied from the website or from another place with a description of the company)',
    },
    'Choose File to Upload (max 10mb)': 'Logo (Max Upload 10mb)',
    Address: 'Address *',
    'Website Homepage': 'Website Homepage',
    'Website Jobs Page': 'Website Jobs Page',
    apply: {
      fillDetails: 'Fill your details below to apply for this position',
      fullName: 'Full Name',
      email: 'Email',
      cv: 'CV',
      cover: 'Cover Letter',
      applyTo: 'Apply To ',
    },
    industries: {
      'Life Science': 'Life Science',
      Tech: 'Tech',
      'Industrial Design': 'Industrial Design',
      Nonprofit: 'Nonprofit',
      Government: 'Government',
      Media: 'Media',
      Finance: 'Finance',
      Culture: 'Culture',
      Nature: 'Nature',
      Infrastructure: 'Infrastructure',
      Gaming: 'Gaming',
      Travel: 'Travel',
      Health: 'Health',
      Print: 'Print',
      Education: 'Education',
      Manufacturing: 'Manufacturing',
      Food: 'Food',
      Shopping: 'Shopping',
      Transportation: 'Transportation',
      Construction: 'Construction',
      Military: 'Military',
      Telecommunications: 'Telecommunications',
      Advertising: 'Advertising',
      'Real Estate': 'Real Estate',
    },
    enterPassword: 'Enter Password To Edit',
    password: 'Password',
    submit: 'Submit',
    newPosition: 'Add a new Position',
    position: 'position',
    save: 'Save',
    cancel: 'cancel',
    delete: 'Delete',
    positionName: 'Position Name *',
    applicationEmail: 'Application Email',
    Description: 'Description',
    experienceLevel: 'Experience Level',
    rawLocation: 'Location',
    jobDescription: 'Job Description',
    jobLink: 'Link to position',
    timeScope: 'Type of employment',
    uploading: 'Uploading...',
    done: 'Done!',
    error: 'Error!',
    deletePosition: 'Delete Position',
    enterPositionTitle: 'Enter Position Title',
    toDelete: 'to delete',
    companies: 'Companies',
    search: 'Search...',
    filter: 'Filter',
    industry: 'Industry',
    experience: 'Experience',
    'Not Specified': 'Not Specified',
    'Junior Level': 'Junior Level',
    'Senior Level': 'Senior Level',
    'Part-time': 'Part-time',
    'Full-time': 'Full-time',
    new: 'New',
    jobs: 'Jobs',
    jobsFound: 'Jobs',
    remotePositions: {
      text: 'Want to work from home?',
      br7Text: 'Hybrid work',
      'Remote Global': 'Remote Global',
      'Remote Israel': 'Remote Israel',
    },
    remote: 'remote / flexible',
    loadMore: 'Load More',
    byName: 'By Name',
    numberOfJobs: 'Number of Jobs',
    sort: 'Sort',
    Website: 'Website',
    spellingError: 'Please check that you have the correct spelling',
  },
  he: {
    tags: 'תגיות',
    sortTypes: {
      text: 'מיון',
      types: {
        date: 'תאריך',
        name: 'שם החברה',
      },
    },
    imageError: 'גודל התמונה צריך להיות פחות מ-10 מ״ב',
    linkError: 'הקישור חייב להיות תקף',
    compNameError: 'יש כבר חברה בשם הזה',
    success: 'הבקשה נשלחה - הוסף חברה נוספת',
    fail: 'משהו לא בסדר',
    applyHere: 'הגש מועמדות',
    addMyCompany: 'צרפו את החברה שלי',
    disclaimer: 'תקנון',
    disclaimerContent: '{content}',
    'Company Name*': 'שם החברה *',
    Tagline: 'סלוגן',
    'Email for Applications*': 'מייל להגשת מועמדות *',
    'Company career page link': 'קישור לעמוד משרות',
    Notes: 'הערות',
    description: {
      value: 'תיאור החברה',
      title:
        'מה החברה עושה? בכמה מילים בודדות (אפשר להעתיק גם מהאתר או ממקום אחר עם תיאור על החברה)',
    },
    'Choose File to Upload (max 10mb)': 'לוגו (קובץ עד 10מ״ב)',
    Address: 'כתובת *',
    'Website Homepage': 'קישור לדף הבית באתר',
    'Website Jobs Page': 'קישור לדף משרות באתר',
    apply: {
      fillDetails: 'מלא את הפרטים להגשת מועמדות',
      fullName: 'שם מלא',
      email: 'כתובת מייל',
      cv: 'קורות חיים',
      cover: 'מכתב אישי',
      applyTo: 'הגשת מועמדות ל',
    },
    industries: {
      'Life Science': 'מדעי החיים',
      Tech: 'טכנולוגיה',
      'Industrial Design': 'עיצוב תעשייתי',
      Nonprofit: 'ללא מטרות רווח',
      Government: 'ממשלתי',
      Media: 'מדיה',
      Finance: 'פיננסי',
      Culture: 'תרבות',
      Nature: 'טבע',
      Infrastructure: 'תשתיות',
      Gaming: 'גיימינג',
      Travel: 'נסיעות',
      Health: 'בריאות',
      Print: 'פרינט',
      Education: 'חינוך',
      Manufacturing: 'ייצור',
      Food: 'מזון',
      'Real Estate': 'נדלן',
      Shopping: 'קניו ',
      Transportation: 'הובלה',
      Construction: 'בנייה',
      Military: 'צבא',
      Telecommunications: 'תקשורת',
      Advertising: 'פרסום',
    },
    enterPassword: 'הזן סיסמא להתחיל לערוך',
    password: 'סיסמא',
    submit: 'אישור',
    newPosition: 'הוספת משרה חדשה',
    position: 'משרה',
    save: 'שמירה',
    cancel: 'ביטול',
    delete: 'מחיקה',
    positionName: 'שם המשרה *',
    applicationEmail: 'מייל למשלוח',
    Description: 'תיאור',
    experienceLevel: 'רמת ניסיון',
    rawLocation: 'מיקום',
    jobDescription: 'תיאור המשרה',
    jobLink: 'קישור למשרה',
    timeScope: 'סוג משרה',
    uploading: 'מעלה...',
    done: 'סיים!',
    error: 'שגיאה!',
    deletePosition: 'מחיקת משרה',
    enterPositionTitle: 'כתבו את שם המשרה',
    toDelete: 'בכדי למחוק',
    companies: 'מעסיקים',
    search: 'חיפוש...',
    filter: 'סינון',
    industry: 'ענף',
    experience: 'ניסיון',
    'Not Specified': 'לא מוגדר',
    'Junior Level': 'ללא ניסיון',
    'Senior Level': 'דרוש ניסיון',
    'Part-time': 'משרה חלקית',
    'Full-time': 'משרה מלאה',
    new: 'חדש',
    jobs: 'משרות',
    jobsFound: 'משרות',
    remotePositions: {
      text: 'עובדים מהבית? נסו גם משרות מרחוק',
      br7Text: 'עבודה היברידית',
      'Remote Global': 'עוד משרות מכל העולם',
      'Remote Israel': 'עוד משרות מכל הארץ',
    },
    remote: 'עבודה מרחוק / גמישה',
    loadMore: 'טען עוד',
    byName: 'לפי שם',
    numberOfJobs: 'מספר משרות',
    sort: 'סינון',
    Website: 'לאתר',
    spellingError: 'אין התאמה של המילה נא לבדוק שאין טעות כתיב',
  },
};

const i18n = new VueI18n({
  locale: 'en',
  fallbackLocale: 'he',
  messages: texts,
});

export default i18n;
