<template>
  <div class="comp-pop-box">
    <p
      data-v-34dbded8=""
      class="newcom-pop-head"
      :style="{
        background: colorScheme.gradient,
        color: colorScheme.name === 'white cream' && colorScheme.color,
      }"
    >
      {{ $t('addMyCompany') }}
    </p>
    <div class="prog_wrap">
      <v-progress-linear
        v-if="loading"
        color="#047cff"
        indeterminate
        rounded
        height="6"
        class="progress mb-10"
      />
      <div
        v-else
        height="6"
      />
    </div>
    <form
      id="form"
      ref="form"
      class="new-company-form"
      name="form"
      data-name="form"
      @submit.prevent="sendCompaniesRequest()"
    >
      <div v-if="feedbackVal">
        <v-alert
          border="bottom"
          dismissible
          :type="feedback.error ? 'error' : 'success'"
          text
          class="alert"
        >
          {{ feedbackVal }}
        </v-alert>
      </div>

      <div
        style="margin-bottom: 10px"
        class="reqForm"
      >
        <input
          v-model="request.name"
          class="text-field-add-my"
          type="text"
          name="name"
          :placeholder="$t('Company Name*')"
          required
        >
        <input
          v-model="request.applicationsEmail"
          class="text-field-add-my"
          type="email"
          name="application email"
          :placeholder="$t('Email for Applications*')"
          required
        >

        <input
          v-model="request.address"
          class="text-field-add-my"
          type="text"
          :placeholder="$t('Address')"
          required
        >
        <input
          v-model="request.tagline"
          class="text-field-add-my"
          type="text"
          name="Tagline"
          :placeholder="$t('Tagline')"
        >
        <input
          v-model="request.comment"
          class="text-field-add-my"
          type="text"
          :placeholder="$t('Notes')"
        >
        <transition name="slide-fade">
          <div>
            <div class="input-image">
              <div class="new-logo-up">
                <img
                  v-if="url"
                  :src="url"
                  alt="your image"
                >
                <div v-else>
                  {{ $t('Choose File to Upload (max 10mb)') }}
                </div>
                <input
                  accept="image"
                  class=""
                  type="file"
                  @input="addLogo"
                  @change="onFileChange"
                >
              </div>
            </div>
            <textarea
              v-model="request.description"
              class="text-field-add-my"
              type="text"
              label="Details"
              :placeholder="$t('description').value"
              :title="$t('description').title"
              auto-grow
              rows="1"
            />
            <input
              v-model="request.link"
              class="text-field-add-my"
              type="text"
              name="WebsiteHomepage"
              :placeholder="$t('Website Homepage')"
            >
            <input
              v-model="request.jobBoard"
              class="text-field-add-my"
              type="text"
              name="WebsiteJobsPage"
              :placeholder="$t('Website Jobs Page')"
            >
          </div>
        </transition>
        <input
          v-model="request.companyRefID"
          type="hidden"
          name="companyRefID"
        >
      </div>
      <div class="new-company-btn-wrap">
        <v-btn
          class="login-btn w-button submit-btn new-company-btn"
          :class="{ disabled: disabled }"
          :color="disabled ? '#bbb' : '#047cff'"
          type="submit"
        >
          <div
            v-if="!!feedback"
            :style="{
              color: feedback.success ? 'black' : 'red',
              textAlign: 'center',
            }"
          >
            {{ feedbackVal }}
          </div>
          <div v-else>
            {{ $t('addMyCompany') }}
          </div>
        </v-btn>
      </div>
    </form>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import checkUrl from '@/utils/checkURL';

const initialReqState = {
  name: '',
  tagline: '',
  link: '',
  comment: '',
  companyRefID: '',
  logo: [],
  description: '',
  address: '',
  messageForUser: '',
  applicationsEmail: '',
  jobBoard: '',
};
export default {
  name: 'CompanyForm',
  data() {
    return {
      passedComp: null,
      request: { ...initialReqState },
      loading: false,
      feedback: null,
      url: null,
    };
  },
  mounted() {
    if (!this.request.logo?.selectedImage) return;
    this.url = URL.createObjectURL(this.request.logo.selectedImage);
  },
  methods: {
    imgRules(v) {
      return (v && v?.size < 10000000) || this.$t('imageError');
    },
    urlRules(v) {
      return v === '' || checkUrl(v) || this.$t('linkError');
    },
    onFileChange(e) {
      const file = e.target.files[0];
      this.url = URL.createObjectURL(file);
    },
    addLogo(event) {
      const { files } = event.target;
      this.request.logo = {};
      [this.request.logo.selectedImage] = files;
    },
    compNameExists(val) {
      const result = this.companies.map((c) => c.name?.toLowerCase()).includes(val.toLowerCase());

      return result && this.$t('compNameError');
    },
    preSubmit() {
      const {
        request: {
          name,
          link,
          jobBoard,
          logo: { selectedImage },
        },
        noFile,
        urlRules,
        imgRules,
        compNameExists,
      } = this;

      const isValidImg = imgRules(selectedImage);
      const isValidName = compNameExists(name);

      if (typeof isValidName === 'string') return isValidName;
      const pass = !noFile && isValidImg && urlRules(link) && urlRules(jobBoard);
      if (pass && typeof isValidImg === 'string') return isValidImg;

      return pass;
    },
    async sendCompaniesRequest() {
      const {
        preSubmit,
        request,
        $store: { dispatch },
      } = this;
      const validated = preSubmit();
      this.feedback = validated;
      if (typeof validated === 'string') {
        return (this.feedback = { error: validated });
      }

      try {
        this.loading = true;
        this.feedback = { success: this.$t('uploading') };
        const result = await dispatch('newUserCompanyReq', {
          ...request,
          fromAdmin: false,
        });
        this.loading = false;
        this.feedback = { success: this.$t('success') };
        console.log('result', result);

        this.$nextTick(() => {
          this.request = { ...initialReqState };
          this.$refs.form.reset();
          console.log('request', this.request.name);
        });
      } catch (error) {
        this.loading = false;
        console.log('error', error);
        this.feedback = { error: this.$t('fail') };
      }
    },
  },
  computed: {
    ...mapState(['companies']),
    ...mapGetters(['colorScheme']),
    noFile() {
      return Array.isArray(this.request.logo);
    },
    notification() {
      return this.request?.messageForUser;
    },
    disabled() {
      const {
        request: { name, address, applicationsEmail },
      } = this;
      return !name || !address || !applicationsEmail;
    },
    feedbackVal() {
      return this?.feedback?.success || this?.feedback?.error;
    },
  },
};
</script>

<style>
.text-field-add-my {
  height: 50px;
  margin-bottom: 20px;
  border: solid 1px #e7e7e7;
  border-radius: 4px;
  padding: 10px 20px;
  width: 100%;
}
textarea.text-field-add-my {
  min-height: 100px;
}

.text-field-add-my:focus {
  border-color: #047cff;
}
.new-company-btn-wrap button {
  border: none !important;
}
</style>

<style lang="scss" scoped>
* {
  transition: all 1s ease-in-out;
}

.b-select .v-menu__content.theme--light.v-menu__content--fixed.menuable__content__active {
  top: 248px !important;
}
.alert {
  transition: all 1s ease-in-out !important;
}
.prog_wrap {
  position: relative;
  top: -55px;

  .progress {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
  }
}
.placeholder {
  *,
  ::placeholder,
  ::-webkit-input-placeholder {
    color: #000 !important;
  }
}
.v-btn:not(.v-btn--round).v-size--default {
  height: 50px;
}
.new-company-btn-wrap {
  position: sticky;
  bottom: 2vh;
  z-index: 5;
  width: 100%;
}

.new-company-btn {
  border: 1px solid #e0ecfa;
  background: #e0ecfa;
  color: #047cff;
  border-radius: 30px;
  padding: 4px 20px;
  transition: all 0.2s ease;
  cursor: pointer;
  text-align: center;
  box-shadow: none;
  display: inline-block;
  width: 90px;
  letter-spacing: 0;
  font-weight: 400 !important;
}
.reqForm {
  margin-bottom: 10px;
  display: flex;
  flex-direction: column;

  .autoComplete {
    margin: 0px 0;
  }
}
.comp-pop-box {
  margin: 0 auto;
  border-radius: 10px;
  box-shadow: 0 0 30px 10px rgb(0 0 0 / 12%);
  overflow: auto;
  background: #fff;
  min-height: 400px;
  padding: 0;
  position: relative;
  max-height: 90vh;
  height: max-content;

  &,
  * {
    max-width: 580px;
  }
  .newcom-pop-head {
    position: absolute;
    z-index: 50;
    width: 100%;
    border-radius: 10px 10px 0 0;
    max-width: none;
  }
}
.new-company-form {
  margin: 75px 30px 30px;
  box-shadow: #fff inset;
}
.comp-new-form {
  float: right;
  transition: all 200ms ease;
  color: #047cff;
  cursor: pointer;
  width: 100%;
  padding: 14px;
  background: white;
  border: #047cff solid 1px;
  border-radius: 10px;
  margin-bottom: 20px;
  margin-top: 20px;
}
.add-comp {
  display: inline-block;
  width: 31%;
  margin-right: 1%;
  margin-left: 1%;
  vertical-align: top;
  padding: 14px;
  font-size: 16px;
  margin-bottom: 0px;
}
.save-comp {
  width: 12%;
  padding-bottom: 9px;
  padding-top: 9px;
}
.submit-btn {
  font-weight: bold;
  align-content: center;
  text-align: center;
  display: inline-block;
  position: absolute;
  bottom: 25px;
  width: 90%;
  right: 5%;
}

.submit-btn.new-company-btn {
  position: relative;
  margin: 0 auto -40px auto;
  width: 100%;
  right: unset;
  box-shadow: 0 0 20px 20px #fff;
}
.submit-btn.new-company-btn.disabled {
  color: grey !important;
  border: 1px solid grey !important;
  cursor: not-allowed;
}

.v-progress-linear {
  width: 90%;
  margin: 50px auto;
}

.slide-fade-enter-active {
  transition: all 0.5s ease;
}
.slide-fade-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}
.slide-fade-enter,
.slide-fade-leave-to {
  transform: translateY(-10px);
  opacity: 0;
}
.new-logo-up img {
  max-width: 100%;
}
</style>
<style>
.text-field-add-my {
  height: 50px;
  margin-bottom: 20px;
  border: solid 1px #e7e7e7;
  border-radius: 4px;
  padding: 10px 20px;
  width: 100%;
}
textarea.text-field-add-my {
  min-height: 100px;
}

.text-field-add-my:focus {
  border-color: #047cff;
}
.new-company-btn-wrap button {
  border: none !important;
}
.pop-box {
  margin: 50px auto !important;
}
.bglpopup.show {
  overflow: hidden !important;
}
</style>
