import {
  cancel, format, register, render,
} from 'timeago.js';
import Vue from 'vue';
import Popup from 'vue-bgl-popup';
import Multiselect from 'vue-multiselect';
import vSelect from 'vue-select';
import VueGtag from 'vue-gtag';
import VueCompositionAPI from '@vue/composition-api';
import heTimeAgo from '@/utils/he-timeAgo';
import i18n from '@/plugins/i18n';
import App from './App.vue';
import ldng from './components/Loading.vue';
import vuetify from './plugins/vuetify';
import router from './router';
import store from './store';

import 'vue-select/dist/vue-select.css';

Vue.use(VueCompositionAPI);
Vue.use(
  VueGtag,
  {
    config: { id: 'UA-37986837-2' },
    appName: 'Freshboard Website',
  },
  router,
);

Vue.component('VSelect', vSelect);

Vue.component('Popup', Popup);

// register globally
Vue.component('Multiselect', Multiselect);
Vue.component('Loading', ldng);

Vue.config.productionTip = false;

register('he', heTimeAgo);
Vue.prototype.$timeAgo = {
  format,
  render,
  cancel,
  register,
};

new Vue({
  router,
  store,
  i18n,
  vuetify,
  render: (h) => h(App),
}).$mount('#app');
