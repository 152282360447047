<template>
  <v-app
    v-if="boardLanguage"
    :class="{ rtl: isRTL }"
  >
    <router-view
      :board-language="boardLanguage"
      @changeLanguage="changeLanguage"
    />
  </v-app>

  <!-- <div
    v-else
    class="full-loading"
  >
    <loading />
  </div> -->
</template>

<script>
import i18n from '@/plugins/i18n';

export default {
  data: () => ({
    boardLanguage: '',
    currentBoard: null,
    changingLocale: false,
  }),
  computed: {
    isRTL() {
      return i18n.locale !== 'en';
    },
  },
  watch: {
    // currentBoard(val) {
    //   let lang = 'en';
    //   const yesWeCan = val?.allowLanguageChange;
    //   const boardLang = val.language;
    //   if (boardLang) {
    //     if (boardLang._id === 'c44hrpi23akg00eqq800') lang = 'he';
    //     if (boardLang._id === 'c44hrpi23akg00eqq80g') lang = 'en';
    //     if (yesWeCan) {
    //       lang = localStorage?.getItem('lang') || lang;
    //       lang = this.$router?.history?.current?.query?.lang || lang;
    //     }
    //   }
    //   this.boardLanguage = lang;
    //   i18n.locale = this.boardLanguage;
    // },
  },
  async mounted() {
    this.$nextTick(async () => {
      await this.$store.dispatch('getBoardData');
      this.setLanguage();
      //   setTimeout(() => {
      //     i18n.locale = this.boardLanguage;
      //   }, 0);
      //   const lang = this.boardLanguage || localStorage.getItem('lang');
      //   if (lang) return this.changeLanguage(lang);
      //   if (!this.currentBoard.allowLanguageChange) return null;
      //   const prevQuery = await this.getQuery();
      //   if (prevQuery.lang) return this.changeLanguage(prevQuery.lang);
      //   return null;
    });
  },
  methods: {
    setLanguage() {
      const { board } = this.$store.getters;
      let lang = 'en';
      const yesWeCan = board?.allowLanguageChange;
      const boardLang = board.language;
      if (boardLang) {
        if (boardLang._id === 'c44hrpi23akg00eqq800') lang = 'he';
        if (boardLang._id === 'c44hrpi23akg00eqq80g') lang = 'en';
        if (yesWeCan) {
          lang = localStorage?.getItem('lang') || lang;
          lang = this.$router?.history?.current?.query?.lang || lang;
        }
      }
      this.boardLanguage = lang;
      i18n.locale = this.boardLanguage;
    },
    getQuery() {
      return new Promise((resolve) => setTimeout(() => {
        const { query } = this.$router.history.current;
        resolve(query);
      }, 150));
    },
    async changeLanguage(lang) {
      if (!this.currentBoard.allowLanguageChange) return;
      i18n.locale = lang;
      localStorage.setItem('lang', lang);
      const prevQuery = await this.getQuery();
      this.$router.push({ query: { ...prevQuery, lang } }).catch((error) => {
        if (error.name !== 'NavigationDuplicated') {
          throw error;
        }
      });
    },
  },
};
</script>
<style lang="scss">
/* Remove all the styles of the "User-Agent-Stylesheet", except for the 'display' property */
*:where(:not(iframe, canvas, img, svg, video):not(svg *, [contenteditable='true'])) {
  all: unset;
  display: revert;
}
/* Preferred box-sizing value */
*,
*::before,
*::after {
  box-sizing: border-box;
}
/*
  Remove list styles (bullets/numbers)
  in case you use it with normalize.css
*/
ol,
ul {
  list-style: none;
}
/* For images to not be able to exceed their container */
img {
  max-width: 100%;
}
/* Removes spacing between cells in tables */
table {
  border-collapse: collapse;
}
/* Revert the 'white-space' property for textarea elements on Safari */
textarea {
  white-space: revert;
}
#app {
  font-family: revert;
  a:where(:not(.job-list-logo, .comp-copy, .pubbtn, .menu-tabs, .router-link-exact-active, .delete-btn, .compBoard
        *, .small-btn.w-button)),
  .info,
  .blue,
  .newcom-body {
    color: unset;
    border: revert !important;
    background: unset !important;
    line-height: unset;
  }
  .name,
  .delete-btn,
  .bar,
  .top-links,
  .small-btn.w-button {
    color: #fff;
  }
  // font-family: revert;
  // font-weight: unset;
  display: revert;
  position: revert;
  .v-application {
    .info-line {
      margin-bottom: revert;
    }
  }
  .v-label.v-label--active {
    margin: -10px 0 0 0px;
  }
}
.comp-copy-fix {
  display: inline-block;
  margin-top: 6px;
}
.login-btn {
  color: white !important;
  text-align: center;
}
.new-logo-up {
  width: 100%;
  border: #e7e7e7 1px dashed;
  border-radius: 4px;
  text-align: center;
  padding: 30px;
  cursor: pointer;
  position: relative;
  color: gray;
  line-height: 1.3;
  transition: ease 200ms all;
  margin-bottom: 20px;
}
.new-logo-up:hover {
  border: #047cff 1px dashed;
}
.new-logo-up span {
  font-size: 12px;
}
.input-image img {
  max-width: 90%;
}
.new-logo-up > input[type='file'] {
  opacity: 0;
  position: absolute;
  right: 0;
  left: 0;
  top: 0;
  bottom: 0;
  cursor: pointer;
}
@media screen and (max-width: 767px) {
  .comp-pop-box {
    padding: 15px;
  }
}
</style>
<style>
.disclaimer {
  display: flex;
  gap: 2rem;
  flex-direction: column;
  max-width: 400px;
  text-align: center;
  place-content: center;
  margin: 0 auto 2rem;
  padding: 0 20px;
}
.full-loading {
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  text-align: center;
  z-index: 100;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 15vh;
}
.enterExit-enter-active,
.enterExit-leave-active {
  transition: opacity 0.5s;
}
.enterExit-enter,
.enterExit-leave-to {
  opacity: 0;
}
</style>
